.intro {
    background-color: #171717;
    padding-top: 200px;
    padding-bottom: 200px;
}

.intro-text {
    width: 100%;
}

.white {
    color: white;
}

.project {
    background-color: #131928;
    padding-bottom: 200px;
}

.project-title-container {
    padding-top: 40px;
    padding-bottom: 40px;
}

.project-list-container {
    display: flex;
}



.footer {
    background-color: #171717;
    height: 300px;
}

.footer-icon {
    color: white;
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.footer-icon:hover {
    color: gray;
    cursor: pointer;
    transition: 0.2s all ease-out;
    text-decoration: none;
}

@media only screen and (max-width: 400px) {
    .project-list-container {
        flex-direction: column;
    }
    .intro {
        background-color: #171717;
        padding-top: 150px;
        padding-bottom: 150px;
    }
}