.projectcard {
    width: 220px;
    height: 380px;
    margin-left: 40px;
    margin-right: 40px;
}

.projectcard-img {
    width: 220px;
    height: 290px;
    background-color: white;
}

.projectcard-title {
    font-size: 20px;
    color: white;
}

.projectcard-desc {
    font-size: 16px;
    color: #5e6e92;
}

.projectcard-platform-icon {
    margin-right: 10px;
    cursor: pointer;
    color: #8e94a0;
    opacity: 80%;
}

.projectcard-platform-icon:hover {
    transition: 0.2s ease-in-out all;
    color: #5e6e92;
}

@media only screen and (max-width: 600px) {
    .projectcard {
        width: 100px;
        height: 180px;
        padding-bottom: 50px;
    }

    .projectcard-img {
        width: 100px;
        height: 130px;
    }
    .projectcard-title {
        font-size: 12px;
    }
    
    .projectcard-desc {
        font-size: 8px;
    }
}