

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.border
{
  border: 2px solid red;
}

.text-center
{
  text-align: center;
}

.header
{
  font-size: 32px;
}